// JS Goes here - ES6 supported
import $ from 'jquery';
window.jQuery = $;
// import 'owl.carousel';
import 'slick-slider';

$('.nav-trigger').on('click', (e) => {
    var $this = $(e.currentTarget);
    var navVisible = 'nav-is-visible';
    // ... deal with $this
    if ($this.hasClass(navVisible)) {
        $this.removeClass(navVisible);
        $('main').removeClass(navVisible);
        $('header').removeClass(navVisible);
    $('footer').removeClass(navVisible);
        $('.mobile-navigation').removeClass(navVisible);
    } else {
        $this.addClass(navVisible);
         $('main').addClass(navVisible);
         $('header').addClass(navVisible);
         $('footer').addClass(navVisible);
         $('.mobile-navigation').addClass(navVisible);
    }
});


$(window).scroll(function() {
    $(window).scrollTop() >= 15 ? $('header').addClass('is-scroll') : $('header').removeClass('is-scroll');
});

// $(document).ready(function() {
$('.slick-slider').slick({
    dots: true,
    fade: true,
    cssEase: 'linear'
});
// });
